import { mapActions, mapGetters, mapMutations } from "vuex"
import { getListOfStrings } from "@/utils"
import { getFootersForTable, getHeadersForTable } from "@/utils/table"
import { TABLE_NAMES } from "@/constants"
import { MAX_CHARACTER_LIMIT } from "../../constants"
import ThemisInput from "@/components/shared/input"

export default {
  name      : "Roles",
  components: {
    ThemisInput
  },
  data() {
    return {
      filters                            : undefined,
      search                             : undefined,
      showAddRoleDialog                  : false,
      isRoleNameDuplicate                : false,
      customRoleNameCharacterLimit       : MAX_CHARACTER_LIMIT.ROLE_NAME,
      customRoleDescriptionCharacterLimit: MAX_CHARACTER_LIMIT.ROLE_DESCRIPTION,
      allPermissions                     : [],
      permissionsToBeAdded               : [],
      role                               : {
        name       : null,
        description: null
      }
    }
  },
  methods: {
    ...mapActions({
      addRole: "roles/addRole",
      notify : "shared/notify"
    }),
    ...mapMutations({
      setRoleAddError: "roles/setRoleAddError"
    }),
    /**
     * This method is used custom filtering for vuetify data table.
     * This will be called for every cell value in the table.
     * @param {*} value is content of each cell in a the table
     */
    customFilterForTable(value) {
      return this.filters.findIndex(element => value?.toString().includes(element)) + 1
    },
    async onRowSelection({ item, value }) {
      await this.$nextTick()

      const dependentPermissions = this.allPermissions.filter(permission =>
        permission.dependency &&
        permission.dependency.find(dependency => dependency.permission === item.permission)
      )

      for (const permission of dependentPermissions) {
        if (value) {
          if (permission.dependencyCondition === "AND") {
            permission.choosable = permission.dependency.every(dependency =>
              this.permissionsToBeAdded.find(addedPermission => addedPermission.permission === dependency.permission)
            )
          } else {
            permission.choosable = true
          }
        } else if (permission.choosable) {
          if (permission.dependencyCondition === "OR") {
            permission.choosable = permission.dependency.some(dependency =>
              permission.permission !== dependency.permission &&
              this.permissionsToBeAdded.some(permission => permission.permission === dependency.permission)
            )
          } else {
            permission.choosable = false
          }
          if (!permission.choosable) {
            const indexOfPermission = this.permissionsToBeAdded.indexOf(permission)
            if (indexOfPermission !== -1) {
              this.permissionsToBeAdded.splice(this.permissionsToBeAdded.indexOf(permission), 1)
            }
          }
        }
      }

      this.updateAllSelectablePermissions()
    },
    updateAllSelectablePermissions() {
      for (const permission of this.allPermissions) {
        if (permission.dependencyCondition === "AND") {
          permission.choosable = permission.dependency.every(dependency =>
            this.permissionsToBeAdded.some(addedPermission => addedPermission.permission === dependency.permission)
          )
        } else if (permission.dependencyCondition === "OR" || (!permission.dependencyCondition && permission?.dependency?.length)) {
          permission.choosable = permission.dependency.some(dependency =>
            permission.permission !== dependency.permission &&
            this.permissionsToBeAdded.some(permission => permission.permission === dependency.permission)
          )
        } else {
          permission.choosable = true
        }

        const isSelectedPermission = this.permissionsToBeAdded.
          find(addedPermission => addedPermission.permission === permission.permission)

        if (!permission.choosable && isSelectedPermission) {
          this.permissionsToBeAdded = this.permissionsToBeAdded.
            filter(addedPermission => addedPermission.permission !== permission.permission)
        }
      }
    },
    handleCancelAddRole() {
      this.showAddRoleDialog = false,
      this.role = {
        name       : null,
        description: null
      }
      this.permissionsToBeAdded = []
    },
    handleAddRole() {
      const permissionsToAdd = []
      for (const permissionToAdd of this.permissionsToBeAdded) {
        const permission = this.selectablePermissions.find(permission => permission.name === permissionToAdd.permission)
        if (permission) {
          permissionsToAdd.push(permission)
        }
      }
      const payload = {
        name       : this.role.name,
        description: this.role.description,
        permissions: permissionsToAdd
      }
      this.addRole(payload)
    },
    handleRoleRowClick(role) {
      if (role && !role.systemDefault) {
        this.$router.push({ name: "role", params: { id: role.id } })
      }
    },
    generateSelectablePermissions(permissions) {
      const temporaryPermission = permissions.map(eachPermission => {
        const result = {
          id       : eachPermission.id,
          name     : eachPermission.name,
          choosable: !eachPermission.dependencies
        }
        if (eachPermission.dependencies) {
          result.dependencies = eachPermission.dependencies
        }
        return result
      })

      const permissionMap = {}
      for (const permission of temporaryPermission) {
        permissionMap[permission.id] = permission
        delete permissionMap[permission.id].id
      }

      const allPermissions = temporaryPermission.map(eachPermission => {
        const result = {
          permission: eachPermission.name,
          choosable : eachPermission.choosable
        }
        if (eachPermission.dependencies) {
          result.dependencyCondition = eachPermission.dependencies.dependencyCondition
          result.dependency          = eachPermission.dependencies.dependency.map(eachDependency => {
            return {
              permission: permissionMap[eachDependency].name,
              choosable : permissionMap[eachDependency].choosable
            }
          })
        }
        return result
      })
      return allPermissions
    },
    getDisplayNameForPermission(permissionName) {
      return this.$t(this.$CONSTANTS.PERMISSION_NAME_TO_DISPLAY_NAME_MAP[permissionName]) || permissionName
    }
  },
  computed: {
    ...mapGetters({
      roles               : "roles/roles",
      isLoadingRoles      : "roles/isLoadingRoles",
      isAddingRole        : "roles/isAddingRole",
      isRoleAdded         : "roles/roleAdded",
      roleAddError        : "roles/roleAddError",
      permissions         : "permissions/permissions",
      isCustomRolesEnabled: "configurations/isCustomRolesEnabled"
    }),
    rolesForTable: function() {
      return this.roles.map(role => {
        const applicable   = role.global ? this.$t("82") : (role.issue ? this.$t("83") : "")
        const roleForTable = {
          id           : role.id,
          name         : role.name,
          systemDefault: role.systemDefault,
          description  : role.description,
          applicable
        }
        return roleForTable
      })
    },
    headersForTable() {
      return this.$TABLES.ROLES.headers.map(header => {
        return {
          ...header, ...{
            text: this.$t(header.text)
          }
        }
      })
    },
    footersForTable() {
      return getFootersForTable(TABLE_NAMES.ROLES, this.$t.bind(this))
    },
    itemsForSearch() {
      return getListOfStrings(this.rolesForTable)
    },
    headersForAddRoleAndPermissionsTable() {
      return getHeadersForTable(TABLE_NAMES.ROLE_ADD_PERMISSIONS, this.$t.bind(this))
    },
    disableRoleAdd() {
      const isRoleValid = this.role.name && this.role.description
        && this.role.name?.length <= this.customRoleNameCharacterLimit
        && this.role.description?.length <= this.customRoleDescriptionCharacterLimit
        && this.permissionsToBeAdded.length > 0
      return !isRoleValid
    },
    selectablePermissions() {
      return this.permissions.filter(permission => permission.selectable)
    }
  },
  watch: {
    filters: function(newFilters) {
      this.search = newFilters ? newFilters.toString() : undefined
    },
    showAddRoleDialog: {
      handler: function(value) {
        if (!value) {
          this.permissionsToBeAdded = []
        }
      }
    },
    "role.name": {
      handler: function() {
        if (this.isRoleNameDuplicate) {
          this.setRoleAddError(null)
        }
      }
    },
    roleAddError: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue?.field === "name" && newValue?.type === "duplicate") {
          this.isRoleNameDuplicate = true
        } else {
          this.isRoleNameDuplicate = false
        }
      }
    },
    isRoleAdded: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "1976"
          })
          this.showAddRoleDialog = false
          this.role              = {
            name       : null,
            description: null
          }
        }
      }
    },
    permissions: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.allPermissions = this.generateSelectablePermissions(this.selectablePermissions)
        }
      }
    }
  }
}